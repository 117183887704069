import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => {

	return (<header class={style.header}>
		<a class={style.logo}>
			<h1>SMS WATCH 👀 <span style={{fontSize: 13}}>v1.3.1</span></h1>
		</a>
		<a class={style.mobile}>
			<h1>{new URLSearchParams(location.search).get('phone')}</h1>
		</a>
		<nav>
		</nav>
	</header>)
};

export default Header;
